import Ajv from 'ajv'
const ajv = new Ajv({
  unicodeRegExp: false,
  code: { es5: true },
})

const schema = {
  type: 'object',
  properties: {
    email: { type: 'string' },
    password: { type: 'string' },
  },
  required: ['email', 'password'],
  additionalProperties: false,
}

export const validate = ajv.compile(schema)
