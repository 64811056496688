/* istanbul ignore file */
import { CUSTOM_HEADER_FLIER_APP } from '@/utils/constant'
/**
 * クライアントからNodeサーバーへのAPI通信を扱うクラス
 * リクエストの際URLのプレフィックスにBASE_PATHを付与しているのは、
 * 旧フロント環境のnginxにて新フロント環境からのリクエストということを判別するため
 */
export const apiProxy = {
  get,
  post,
  del,
}

function get<T>(url: string, options?: RequestInit): Promise<T> {
  const defaultOptions: RequestInit = {
    cache: 'no-cache',
    headers: {
      Pragma: 'no-cache', // IE11対応
      ...CUSTOM_HEADER_FLIER_APP,
    },
  }
  return new Promise<T>((resolve, reject) => {
    fetch(process.env.BASE_PATH + url, { ...defaultOptions, ...options })
      .then((response) => {
        return resolve(response.json())
      })
      .then((data) => reject(data))
  })
}

function post<T>(url: string, body?: Record<string, unknown>): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    fetch(process.env.BASE_PATH + url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        ...CUSTOM_HEADER_FLIER_APP,
      },
    })
      .then((response) => {
        return resolve(response.json())
      })
      .then((data) => reject(data))
  })
}

function del<T>(url: string, body?: Record<string, unknown>): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    fetch(process.env.BASE_PATH + url, {
      method: 'DELETE',
      body: JSON.stringify(body),
      headers: {
        ...CUSTOM_HEADER_FLIER_APP,
      },
    })
      .then((response) => {
        return resolve(response.json())
      })
      .then((data) => reject(data))
  })
}
