import crypto from 'crypto'
/**
 * 数字を指定桁数で0埋めした文字列を返す
 */
export function zeroPadding(num: number, len: number): string {
  if (String(num).length > len) {
    return String(num)
  }
  return (Array(len).join('0') + num).slice(-len)
}

/**
 * 指定サイズで分割した配列を返す
 *
 * e.g.
 * param: arr = [1, 2, 3, 4, 5], chunkSize = 3
 * return: [[1, 2, 3], [4, 5]]
 */
export function chunk<T>(arr: T[], chunkSize: number): T[][] {
  if (chunkSize <= 0) throw 'Invalid chunk size'
  const result = []
  for (let i = 0, len = arr.length; i < len; i += chunkSize)
    result.push(arr.slice(i, i + chunkSize))
  return result
}

/**
 * rot13(シーザー暗号)化した文字列を返す
 */
export function rot13(str: string): string {
  return str.replace(/[a-zA-Z]/g, function (chr) {
    const start = chr <= 'Z' ? 65 : 97
    return String.fromCharCode(start + ((chr.charCodeAt(0) - start + 13) % 26))
  })
}

/**
 * 暗号化した文字列を返す
 */
export function encrypt(str: string): string {
  return rot13(Buffer.from(str, 'binary').toString('base64'))
}

/**
 * 復号化した文字列を返す
 */
export function decrypt(str: string): string {
  return Buffer.from(rot13(str), 'base64').toString()
}

/**
 * 指定した文字数で省略して返す
 */
export function truncate(str: string, length: number): string {
  if (!str) return ''
  if (str.length > length) {
    return str.slice(0, length) + '...'
  } else {
    return str
  }
}

/**
 * md5化した文字列を返す
 */
export const md5 = (str: string): string => {
  const md5 = crypto.createHash('md5')
  return md5.update(str, 'binary').digest('hex')
}

/**
 * aes-256-ecbで暗号化した文字列を返す
 */
export function aesEncrypt(str: string, pass: string): string {
  const cipher = crypto.createCipher('aes-256-ecb', pass)
  let cipheredData = cipher.update(str, 'utf8', 'base64')
  cipheredData += cipher.final('base64')
  return cipheredData
}

/**
 * aes-256-ecbで復号化した文字列を返す
 */
export function aesDecrypt(cipheredData: string, pass: string): string {
  const decipher = crypto.createDecipher('aes-256-ecb', pass)
  let decipheredData = decipher.update(cipheredData, 'base64', 'utf8')
  decipheredData += decipher.final('utf8')
  return decipheredData
}

/**
 * 続きを読むボタンが必要か判定する
 *
 * @param str 文字列
 * @param maxLine 最大改行数
 * @param maxLength 最大文字数
 * @return  boolean true: 必要、false: 不要
 */
export function needReadMore(
  str: string,
  maxLine: number,
  maxLength: number
): boolean {
  const newlineCount = str.split('\n').length
  return newlineCount > maxLine || str.length > maxLength
}
