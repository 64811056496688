import Ajv from 'ajv'
import addFormats from 'ajv-formats'
const ajv = new Ajv({
  unicodeRegExp: false,
  code: { es5: true },
})
addFormats(ajv, ['email'])

const schema = {
  type: 'object',
  properties: {
    email: { type: 'string', format: 'email' },
  },
  required: ['email'],
  additionalProperties: false,
}

export const validate = ajv.compile(schema)
