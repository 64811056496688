import { DEFAULT_USER_ICON_PATH } from '@/utils/constant'
import { getUserNameColor } from '@/utils/user'

interface Props {
  src: string
  text: string
  size: number
  spSize: number
  className?: string
  fontSize?: string
}

/**
 * アイコンが未設定でユーザー名がある場合は、ユーザー名の先頭文字を表示する
 * それ以外は画像を表示する
 * または施設型ユーザーなどユーザー名がNULLの場合はデフォルトアイコンが表示される(api4側でデフォルト画像がセットされる)
 */
export const UserProfileIcon: React.FC<Props> = ({
  src,
  size,
  spSize,
  text,
  className = '',
  fontSize = '',
}) => {
  /**
   * ユーザー名の先頭文字を表示
   */
  const renderTextIcon = (size, className, fontSize) => {
    const bgColor = getUserNameColor(text)
    fontSize = !fontSize ? `${size * 0.5}px` : fontSize
    return (
      <div
        className={`table font-bold leading-none text-center text-white rounded-full ${className}`}
        style={{ fontSize, width: size, height: size, background: bgColor }}
      >
        <div
          className="table-cell align-middle"
          style={{ width: size, height: size }}
        >
          {text[0]}
        </div>
      </div>
    )
  }

  /**
   * ユーザー画像を表示
   */
  const renderImageIcon = (size, className) => (
    <img
      className={`object-cover rounded-full ${className}`}
      style={{ width: size, height: size }}
      src={src}
      width={size}
      height={size}
      alt="ユーザーアイコン"
    />
  )

  return src === DEFAULT_USER_ICON_PATH && text ? (
    <>
      {renderTextIcon(size, `${className} pc`, fontSize)}
      {renderTextIcon(spSize, `${className} sp`, fontSize)}
    </>
  ) : (
    <>
      {renderImageIcon(size, `${className} pc`)}
      {renderImageIcon(spSize, `${className} sp`)}
    </>
  )
}
