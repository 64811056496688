import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

export const isNew = (released: string) => {
  return dayjs().diff(dayjs(released), 'day') < 7
}

export const getNtjIso8601Format = () => {
  dayjs.extend(utc)
  return dayjs.utc().format('YYYYMMDDTHHmmssZ').replace('+00:00', 'Z')
}
