/* istanbul ignore file */
import { apiProxy } from '@/libs/api/apiProxy'
import {
  UserInfo,
  GetCheckFacilityResponse,
  GetCheckResponse,
  PostLoginResponse,
  PostGetSessionResponse,
  BookPercent,
  PostFollowResponse,
  DeleteFollowResponse,
  PostFollowCancelResponse,
  PostForgotpwdResponse,
  PostResetpwdResponse,
  PostUserInfoResponse,
  PostUserInfoRequest,
  GetCheckValidationResponse,
  PostWithdrawResponse,
  WithdrawType,
  PostEditPickupResponse,
  PostUserBizNotificationResponse,
  GetUserBizNotificationResponse,
  PostUserFollowAcceptResponse,
  PostUserFollowRejectResponse,
  PostUserHistoryResponse,
  PostUserRegistResponse,
  PostUserRegistRequest,
  PostSetuppwdResponse,
} from '@/types/user'
import { validate } from '@/validations/login.validator'
import { validate as validateForgotpwd } from '@/validations/forgotpwd.validator'
import { encrypt } from '@/utils/format'
import { CUSTOM_HEADER_FLIER_APP } from '@/utils/constant'

export const getUserInfo = (): Promise<UserInfo> => {
  return apiProxy.get<UserInfo>('/api/user/info')
}

export const checkFacility = (): Promise<GetCheckFacilityResponse> => {
  return apiProxy.get<GetCheckFacilityResponse>('/api/user/check/facility')
}

export const getCheck = (): Promise<GetCheckResponse> => {
  return apiProxy.get<GetCheckResponse>('/api/user/check')
}

export const getEmailCheck = (
  email: string
): Promise<GetCheckValidationResponse> => {
  return apiProxy.get<GetCheckValidationResponse>(
    `/api/user/check/email?email=${encodeURIComponent(email)}`
  )
}

export const getSrpIdCheck = (
  srpId: string
): Promise<GetCheckValidationResponse> => {
  return apiProxy.get<GetCheckValidationResponse>(
    `/api/user/check/srp_id?srpId=${srpId}`
  )
}

export const getBookPercent = (): Promise<BookPercent[]> => {
  return apiProxy.get<BookPercent[]>('/api/user/book/percent')
}

export const postLogin = (
  email: string,
  password: string
): Promise<PostLoginResponse> => {
  const valid = validate({ email, password })
  if (!valid) return new Promise((resolve) => resolve({ result: false }))
  return apiProxy.post<PostLoginResponse>('/api/user/login', {
    email,
    password,
  })
}

export const postLogout = (): Promise<void> => {
  return apiProxy.post('/api/user/logout')
}

export const postGetSession = (): Promise<PostGetSessionResponse> => {
  return apiProxy.post('/api/user/session')
}

export const postFollow = (srp_id: string): Promise<PostFollowResponse> => {
  return apiProxy.post(`/api/user/follow/${srp_id}`)
}

export const deleteFollow = (srp_id: string): Promise<DeleteFollowResponse> => {
  return apiProxy.del(`/api/user/follow/${srp_id}`)
}

export const postFollowCancel = (
  srp_id: string
): Promise<PostFollowCancelResponse> => {
  return apiProxy.post(`/api/user/follow/cancel/${srp_id}`)
}

export const postFollowAccept = (
  srp_id: string
): Promise<PostUserFollowAcceptResponse> => {
  return apiProxy.post(`/api/user/follow/accept/${srp_id}`)
}

export const postFollowReject = (
  srp_id: string
): Promise<PostUserFollowRejectResponse> => {
  return apiProxy.post(`/api/user/follow/reject/${srp_id}`)
}

export const postForgotpwd = (
  email: string
): Promise<PostForgotpwdResponse> => {
  const valid = validateForgotpwd({ email })
  if (!valid) return new Promise((resolve) => resolve({ result: false }))
  return apiProxy.post<PostForgotpwdResponse>('/api/user/forgotpwd', { email })
}

export const PostResetpwd = (
  email: string,
  password: string,
  reminder: string,
  token: string
): Promise<PostResetpwdResponse> => {
  return apiProxy.post<PostResetpwdResponse>('/api/user/resetpwd', {
    email,
    password,
    token: reminder,
    csrfToken: token,
  })
}

export const PostUserInfo = (
  postUserInfo: PostUserInfoRequest
): Promise<PostUserInfoResponse> => {
  const body = {
    /** TODO：api3側の変数名に合わせて一時的に入れ替えている。api4が実装されたら必要に応じて修正すること */
    introduction: postUserInfo.introduction,
    pickup_id: postUserInfo.pickupId,
    is_opening: postUserInfo.isOpening,
    can_search: postUserInfo.canSearch,
    is_public_stand: postUserInfo.isPublicStand,
    is_public_reading_data: postUserInfo.isPublicReadingData,
    email: postUserInfo.email,
    passwd: encrypt(postUserInfo.password),
    name: postUserInfo.name,
    srp_id: postUserInfo.srpId,
    birth: postUserInfo.birth,
    gender_id: postUserInfo.genderId,
    pref_id: postUserInfo.prefId,
    job_id: postUserInfo.jobId,
    role_id: postUserInfo.roleId,
    favorites: postUserInfo.favorites,
    magazine1: postUserInfo.magazine1,
    token: postUserInfo.token,
    csrfToken: postUserInfo.csrfToken,
  }
  if (postUserInfo.isConfirmed !== undefined) {
    body['is_confirmed'] = postUserInfo.isConfirmed
  }
  if (postUserInfo.isConfigured !== undefined) {
    body['is_configured'] = postUserInfo.isConfigured
  }
  if (postUserInfo.allowSendNewsletter !== undefined) {
    body['allow_send_newsletter'] = postUserInfo.allowSendNewsletter
  }
  if (postUserInfo.allowSendEvent !== undefined) {
    body['allow_send_event'] = postUserInfo.allowSendEvent
  }
  if (postUserInfo.allowSendBookLabo !== undefined) {
    body['allow_send_book_labo'] = postUserInfo.allowSendBookLabo
  }
  return apiProxy.post<PostUserInfoResponse>('/api/user/info', body)
}

export const PostWithdraw = (
  csrfToken: string,
  type: WithdrawType
): Promise<PostWithdrawResponse> => {
  return apiProxy.post<PostWithdrawResponse>('/api/user/withdraw', {
    csrfToken,
    type,
  })
}

export const PostRetire = (
  text: string,
  csrfToken: string
): Promise<PostWithdrawResponse> => {
  return apiProxy.post<PostWithdrawResponse>('/api/user/retire', {
    text,
    csrfToken,
  })
}

export const PostCancel = (
  reasons: string,
  text: string,
  csrfToken: string
): Promise<PostWithdrawResponse> => {
  return apiProxy.post<PostWithdrawResponse>('/api/user/cancel', {
    reasons,
    text,
    csrfToken,
  })
}

export const PostEditPickup = (
  summaryId: number,
  csrfToken: string
): Promise<PostEditPickupResponse> => {
  return apiProxy.post<PostEditPickupResponse>('/api/user/edit_pickup', {
    summary_id: summaryId,
    csrfToken: csrfToken,
  })
}

export const getUserBizNotification = (
  page: number
): Promise<GetUserBizNotificationResponse> => {
  return apiProxy.get<GetUserBizNotificationResponse>(
    `/api/user/biz_notification?page=${page}`
  )
}

export const postUserBizNotification = (
  user_notice_id: number
): Promise<PostUserBizNotificationResponse> => {
  return apiProxy.post<PostUserBizNotificationResponse>(
    '/api/user/biz_notification',
    { user_notice_id }
  )
}

export const postUserProfileImage = async (
  image: FormData
): Promise<PostUserBizNotificationResponse> => {
  return await new Promise<PostUserBizNotificationResponse>(
    (resolve, reject) => {
      return fetch(process.env.BASE_PATH + '/api/user/profile/image', {
        method: 'POST',
        body: image,
        headers: {
          ...CUSTOM_HEADER_FLIER_APP,
        },
      })
        .then((response) => {
          resolve(response.json())
        })
        .then((data) => reject(data))
    }
  )
}

export const postUserHistory = (
  summary_id: number
): Promise<PostUserHistoryResponse> => {
  return apiProxy.post<PostUserHistoryResponse>(
    `/api/user/history/${summary_id}`
  )
}

export const postUserRegist = async (
  params: PostUserRegistRequest
): Promise<PostUserRegistResponse> => {
  const body = {
    email: params.email,
    password: encrypt(params.password),
    name: params.name,
    birth: params.birth,
    gender_id: params.genderId,
    pref_id: params.prefId,
    job_id: params.jobId,
    role_id: params.roleId,
    font_size: params.fontSize,
    favorites: params.favorites,
    referrer: params.referrer,
    user_referrer: params.userReferrer,
    cid: params.cid,
    sid: params.sid,
  }

  return await apiProxy.post<PostUserRegistResponse>('/api/user/regist', body)
}

export const getEmailChecker = (
  email: string,
  pid: string
): Promise<GetCheckValidationResponse> => {
  if (pid != '') {
    return apiProxy.get<GetCheckValidationResponse>(
      `/api/user/email_checker/${pid}?email=${encodeURIComponent(email)}`
    )
  }
  return apiProxy.get<GetCheckValidationResponse>(
    `/api/user/email_checker?email=${encodeURIComponent(email)}`
  )
}

export const postGetPresetIconBlob = async (presetIcon: string) => {
  const res = await fetch(
    process.env.BASE_PATH + '/api/user/profile/preset_icon',
    {
      method: 'POST',
      body: JSON.stringify({ presetIcon }),
      headers: {
        ...CUSTOM_HEADER_FLIER_APP,
      },
    }
  )
  return res.blob()
}

export const postStudentCardImage = async (
  image: string,
  user_id: number
): Promise<PostUserBizNotificationResponse> => {
  const formData = new FormData()
  formData.append('image', image.replace('data:', '').replace(/^.+,/, ''))
  formData.append('user_id', user_id.toString())
  return await new Promise<PostUserBizNotificationResponse>(
    (resolve, reject) => {
      return fetch(process.env.BASE_PATH + `/api/user/student/card`, {
        method: 'POST',
        body: formData,
        headers: {
          ...CUSTOM_HEADER_FLIER_APP,
        },
      })
        .then((response) => {
          resolve(response.json())
        })
        .then((data) => reject(data))
    }
  )
}

export const postStudentUpdate = async (
  token: string,
  sid: string,
  cid: string
): Promise<PostUserBizNotificationResponse> => {
  const body = {
    token: token,
    sid: sid,
    cid: cid,
  }
  return await apiProxy.post<PostUserRegistResponse>(
    '/api/user/student/update',
    body
  )
}

export const postSetuppwd = (
  password: string
): Promise<PostSetuppwdResponse> => {
  return apiProxy.post<PostSetuppwdResponse>('/api/user/setuppwd', { password })
}
