import { postLogout } from '@/api/user'
import {
  BizGroupCluster,
  CourseId,
  PayType,
  ReadStatus,
  Slack,
  Srp,
  UserInfo,
  LimitedTimeUrl,
} from '@/types'
import { getNtjIso8601Format } from './time'

/**
 * コースIDからコース名を取得
 *
 * @param number courseId コースID
 * @return string コース名
 */
export const getCourseName = (courseId: number): string => {
  if (courseId === 1) {
    return 'フリープラン'
  } else if (courseId === 2) {
    return 'シルバープラン'
  } else if (courseId === 3) {
    return 'ゴールドプラン'
  }
  return ''
}

/**
 * SRPの状態を取得
 */
export const getSrpStatus = (user?: UserInfo): Srp => {
  if (!user || !user.use_srp) return Srp.OFF_SRP

  if (user.biz_group_id === 0 || user.biz_group_use_srp === Srp.OPEN_SRP) {
    // 個人SRP ONユーザー or 法人OPENでSRP ONユーザー
    return Srp.OPEN_SRP
  } else if (user.biz_group_use_srp === Srp.CLOSE_SRP) {
    // 法人CLOSEでSRP ONユーザー
    return Srp.CLOSE_SRP
  }
  return Srp.OFF_SRP
}

/**
 * 読書ステータスを返す
 *
 * @param number percent 読了率
 * @return ReadStatus
 */
export const getReadStatus = (percent: number): ReadStatus => {
  if (percent === 100) return ReadStatus.Finish
  if (0 < percent && percent < 100) return ReadStatus.Reading
  return ReadStatus.NotRead
}

/**
 * ユーザーがフライヤー編集部か判定
 *
 * @param true: フライヤー編集部、false: フライヤー編集部ではない
 */
export const isFlierEditor = (userId: number): boolean => {
  return userId === 966715
}

/**
 * ユーザーが法人ユーザーか判定
 *
 * @param true: 法人ユーザー、false: 法人ユーザーではない
 */
export const isBizUser = (bizGroupId: number): boolean => {
  return bizGroupId > 0
}

/**
 * 施設型ユーザーか判定
 * IP施設型、BSSID施設型、URL型いずれかである場合、施設型であるとみなす
 *
 * @param true: 施設型、false: 施設型ではない
 */
export const isFacility = (email?: string): boolean => {
  if (!email) return false
  return (
    email.includes('net.flierinc') ||
    email.includes('bssid.flierinc') ||
    email.includes('limited_url.flierinc')
  )
}

/**
 * URL配布型ユーザーか判定
 *
 * @param true: URL配布型ユーザー、false: URL配布型ユーザーではない
 */
export const isLimitedUrlUser = (email?: string): boolean => {
  if (!email) return false
  return email.includes('limited_url.flierinc')
}

/**
 * みずほユーザーか判定
 * biz_group_id == 255の場合、みずほユーザであるとみなす
 *
 * @param true: みずほユーザー、false: みずほユーザーではない
 */
export const isMhfg = (userInfo: UserInfo): boolean => {
  return (
    userInfo?.result && userInfo?.biz_group_id && userInfo?.biz_group_id == 255
  )
}

export const logout = async (): Promise<void> => {
  try {
    localStorage.removeItem('flier_id')
    await postLogout()
  } catch (e) {
    console.error(e)
  }
}

/**
 * セブン&アイユーザーか判定
 * biz_group_id == 212の場合、セブン&アイユーザであるとみなす
 *
 * @param true: セブン&アイユーザー、false: セブン&アイユーザーではない
 */
export const isSevenAndI = (userInfo: UserInfo): boolean => {
  return (
    userInfo?.result && userInfo?.biz_group_id && userInfo?.biz_group_id == 212
  )
}

/**
 * IAP決済、SPモード決済またはGooglePlay決済の判定
 */
export const isIapOrSpModeOrGooglePlay = (userInfo: UserInfo): boolean => {
  return (
    userInfo?.result &&
    userInfo.course.id >= CourseId.silver &&
    (userInfo.pay_type == PayType.IAP_APPLE ||
      userInfo.pay_type == PayType.DOCOMO ||
      userInfo.pay_type == PayType.GOOGLE)
  )
}

/**
 * UserInfoを元にチャットツールと連携しているかの判定
 */
export const isChatToolConnection = (userInfo: UserInfo): boolean => {
  return (
    userInfo?.result &&
    isBizUser(userInfo?.biz_group_id) &&
    getSrpStatus(userInfo) > Srp.OFF_SRP &&
    (hasSlackTeamId(userInfo?.slack) || hasTeamsTeamId())
  )
}

export const hasSlackTeamId = (slack: Slack): boolean => {
  return slack.team_id !== ''
}

// TODO: Teams連携時に実装する
export const hasTeamsTeamId = (): boolean => {
  return false
}

/**
 * ユーザー名から色を取得
 */
export const getUserNameColor = (userName: string): string => {
  const colors = [
    '#E5352B',
    '#E58600',
    '#E5B800',
    '#27B24A',
    '#00ADA6',
    '#2C99CC',
    '#006EE5',
    '#4E4CBD',
    '#9B49C4',
    '#5E5E5E',
  ]
  let sumCharCode = 0
  for (let i = 0; i < userName.length; i++) {
    sumCharCode += userName.charCodeAt(i)
  }
  return colors[sumCharCode % colors.length]
}

/**
 * クラスター名を取得
 */
export const getClusterName = (
  bizGroupClusters: BizGroupCluster[],
  clusterId: number
) => {
  if (!bizGroupClusters) return ''
  const cluster = bizGroupClusters.find((cluster) => cluster.id === clusterId)
  return cluster ? cluster.name : ''
}

export const sendNtjSites = async (
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  limited_time_url: LimitedTimeUrl
) => {
  e.preventDefault()
  if (!limited_time_url.nt_session_id) {
    return
  }

  await logout()
  const ntSessionId = limited_time_url.nt_session_id
  const timestamp = getNtjIso8601Format()
  // ex) https://nettime.jp/ticket/content/return?ntSessionId=G1L5SIRPGKLSURLBNSQPRS&timestamp=20230101T080101Z
  const url =
    process.env.NET_TIME_URL +
    `/ticket/content/return?ntSessionId=${ntSessionId}&timestamp=${timestamp}`
  try {
    window.location.href = url
  } catch (e) {
    console.error(e)
  }
}
